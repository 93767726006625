@import "../Variables";

* {
  box-sizing: border-box;
}

h1.ant-typography,
h2.ant-typography,
h3.ant-typography,
h4.ant-typography,
h5.ant-typography,
h6.ant-typography,
p.ant-typography,
span.ant-typography,
h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
.ant-typography,
div.ant-typography,
th.ant-table-cell,
label,
input,
input.ant-input,
textarea,
textarea.ant-input,
.ant-form-item-label > label {
  color: $dark;
  margin-top: 0;
  margin-bottom: 0;
}

.dark {
  color: $dark;
}

i {
  // color: darken($color4, 10);
  color: $color1;
  font-style: normal;
}

td {
  color: darken($neutralDark, 21);
}

.ant-input-password-icon.anticon-eye,
.ant-input-password-icon.anticon-eye-invisible {
  color: $color1 !important;
}

h2.ant-typography {
  color: saturate(darken($color1, 20), 20);
  font-size: 1.5em;
  border: 1px solid $neutral;
  padding: 5px 15px;
  border-radius: 10px;
  background-color: lighten($neutralLight, 3);
}

h3.ant-typography {
  font-size: 1rem;
  font-weight: bold;
  color: darken($color1, 20);
}

hr {
  border: unset;
  height: 1px;
  background-color: $neutral;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.highlight {
  color: $color1;
}

.danger {
  color: $color2 !important;
}

.spread {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

button.ant-btn {
  color: $neutralDarker;
  border-color: $neutralMid;
  // font-size: 1em;
  transform: scale(1.2);
  border-radius: 8px;
}

button.ant-btn.ant-btn-primary {
  border-color: $color1;
  background-color: $color1;
  color: $white;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  margin-left: 5px;
  background: $neutralLight;
  border: 1px solid $neutral;
  font-weight: bold;
  color: darken($neutralDark, 15);

  &.ant-tabs-tab-active {
    background-color: #fff;
    color: $neutralDarker;
  }
  &.ant-tabs-tab-disabled {
    background-color: #fff;
    color: $neutralMid;
  }
}

.ant-dropdown {
  @include with-shadow;
}

div.ant-divider {
  border-top-color: $neutral;
}

.ant-table-thead > tr > th.ant-table-cell {
  background-color: lighten($neutralLight, 2);
}

.ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 0 !important;
}

.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 0 !important;
}

.ant-form-item-label > label.ant-form-item-required::before {
  color: $color1 !important;
}

.ant-modal-close-x {
  color: $dark;
}

.ant-modal-root .ant-modal-mask {
  background-color: rgba(darken($dark, 17), 0.6);
}

.ant-modal-root {
  .ant-modal-content {
    @include with-shadow(rgba(darken($dark, 5), 0.4));
  }
}

.ant-dropdown {
  z-index: 2 !important;
}

.non-printable-content {
  @media print {
    display: none;
  }
}

.jpg-image-preview {
  width: 100%;
}

.jpg-image-preview-modal {
  width: 80vw !important;
  height: calc(80vw * 0.71) !important;
  // height: 90vh !important;
  transform-origin: unset !important;

  .ant-modal-content {
    border-radius: 0;
    // background-color: pink;
    width: 80vw;
    height: calc(80vw * 0.71) !important;
  }

  .ant-modal-confirm-content {
    margin-top: 0;
  }

  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-confirm-btns {
    .ant-btn.ant-btn-primary {
      display: none;
    }
    .ant-btn {
      position: fixed;
      top: 3rem;
      right: 10vw;
    }
  }
}

.ant-notification {
  width: auto;

  .ant-notification-notice-message {
    .ant-typography {
      color: #333;
    }
  }
}

.ant-tag.ant-tag-blue {
  color: $neutralDarker;
  border-color: lighten($neutralDarker, 35);
  background-color: rgba($color1, 0.1);
}

.ant-empty {
  .ant-empty-description {
    color: $neutralDark;
    font-weight: bold;
    font-size: 0.75rem;
  }
  .ant-empty-image {
    transform: scale(0.8);
    .ant-empty-img-default-path-1 {
      fill: $neutralDark;
    }
    .ant-empty-img-default-path-5,
    .ant-empty-img-default-path-4 {
      fill: $neutralMid;
    }
    .ant-empty-img-default-ellipse {
      fill: $neutral;
    }
  }
}

button:disabled {
  pointer-events: none;
  background-color: $neutral !important;
  border-color: $neutral !important;
  color: $white !important;
}

.ant-breadcrumb {
  font-size: 1em;
}

.ant-modal-title {
  color: $dark;
}

table {
  border-collapse: collapse;
  width: 100%;

  td,
  th {
    border: 1px solid $neutral;
    padding: 5px;
    text-align: center;
  }
  th {
    color: $dark;
  }
}
