@import "../Variables";

.lesson-page {
  text-align: left;
  padding-bottom: 4rem;

  .ant-breadcrumb {
    text-align: left;
    .ant-breadcrumb-link {
      color: $dark;
    }
    a {
      color: $color1;
    }
  }

  .lesson-title {
    margin-top: 1rem;
    margin-bottom: 2rem;
    text-align: center;
    font-size: 2.5rem;
    color: $dark;
  }

  p {
    margin-bottom: 1.5rem;
  }

  h2 {
    font-size: 1.7rem;
    margin-top: 3rem;
    text-align: center;
    margin-bottom: 1rem;
  }

  h3 {
    margin-bottom: 1rem;
    margin-top: 3rem;
    font-size: 1.2rem;
  }

  ul {
    margin-top: -1rem;
    margin-bottom: 1.5rem;
    li {
      margin-bottom: 0.5rem;
    }
  }

  ol,
  ul {
    li {
      margin-bottom: 1rem;
    }
  }
}
