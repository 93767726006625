@import "../Variables";

.card {
  @include with-shadow;
  text-align: left;
  border: unset;
  border: 1px solid transparent;
  border-radius: 10px !important;

  &.interactive {
    cursor: pointer;
    transition: all 200ms;

    &:hover {
      border-color: $neutralDarker;
      border-width: 2px !important;

      .ant-card-body {
        transition: all 200ms;
        background-color: lighten($neutralLight, 2);
        border-radius: 10px;
      }

      .card {
        @include with-shadow($neutralMid);
      }
    }
  }

  .card-header {
    .ant-divider {
      margin-top: 1rem;
    }
  }

  .card-actions {
    button {
      margin-left: 0.5rem;
    }
  }

  .ant-card-body {
    padding: 1rem;
  }
}
