@import "../Variables";

.code {
  background-color: darken($dark, 15);
  padding: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: $white;
  border-radius: 10px;
  white-space: pre;
  filter: saturate(1.5);
  font-family: "Roboto Mono", monospace;
  font-weight: 500;
  overflow: auto;
  display: block;

  &.inline {
    display: inline-block;
    margin: 0;
    padding: 0;
    margin-left: 0.5rem;
    padding: 0.2rem 1rem;
    vertical-align: middle;
    // font-size: 0.9rem;
    position: relative;
    top: -0.1rem;
  }
}
