@import "../Variables";

.notion {
  position: relative;

  a {
    color: $color1;
    font-weight: bold;
  }

  .notion-label {
    color: $color1;
    background-color: $neutralLight;
    display: inline-block;
    padding: 0.2rem;
    font-weight: bold;
    cursor: pointer;
  }

  .explanation {
    // @include with-shadow;
    box-shadow: unset;
    border: 1px solid $neutral;
    transition: all 300ms;
    position: absolute;
    width: 25rem;
    max-height: 15rem;
    top: 2.5rem;
    left: 0;
    color: $dark;
    // background-color: lighten($neutralLight, 2);
    background-color: $white;
    // background-color: $color1;
    // background-color: $neutralLight;
    // color: $color1;
    // font-weight: bold;
    padding: 1rem;
    overflow-y: auto;
    opacity: 0;
    pointer-events: none;
  }

  &.is-important {
    .notion-label {
      // background-color: lighten($dark, 10);
      // color: $white;
      color: $color1;
      background-color: transparent;
    }

    .explanation {
      // @include with-shadow($dark);
      box-shadow: unset;
      border: 1px solid $neutralDark;
    }
  }

  &:hover {
    .explanation {
      opacity: 1;
      top: 1.5rem;
      z-index: 1;
      pointer-events: all;
    }
  }
}
