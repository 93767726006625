@import "../Variables";

.language-picker {
  width: 100%;
  text-align: right;

  img {
    margin-right: 0.5rem;
    position: relative;
    top: -1px;
    display: inline-block;
  }
}

.language-picker-list {
  img {
    margin-right: 0.5rem;
    position: relative;
    top: -1px;
    display: inline-block;
  }
}
