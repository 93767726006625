$color1: #19aae8;
$color1Light: lighten($color1, 20);
$color2: #ff4d4f;
$color3: #20bf6b;
$color4: #ffbc0d;

$black: #111;
$dark: #004c6d;
$white: #ffffff;

$neutralLight: #eef5f8;
$neutral: darken($neutralLight, 5);
$neutralMid: desaturate(darken($neutral, 7), 5);
$neutralDark: desaturate(darken($neutral, 15), 20);
$neutralDarker: saturate(darken($neutral, 40), 10);

$max-page-width: 1500px;

$andunGreen: #0d9e80;

@mixin with-shadow($shadowColor: $neutral) {
  box-shadow: 0px 0px 15px $shadowColor;
}
