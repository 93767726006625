@import "../Variables";

.code-editor-container {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 1rem;
  background-color: $neutralLight;
  border-radius: 10px;

  // &.stacked {
  .code-editor textarea {
    position: relative;
    top: 3px !important;
  }
  // }

  .code-editor {
    position: relative;
    overflow: hidden;
    background-color: darken($dark, 15) !important;
    border-radius: 10px !important;

    .line-numbers {
      position: absolute;
      top: 10px;
      left: 0.5rem;
      z-index: 1;
      color: lighten(desaturate($dark, 90), 20);
      font-family: "Roboto Mono", monospace !important;

      .line-number {
        display: block;
        text-align: right;
        font-size: 15.6px;
        width: 30px;
      }

      & + div {
        textarea,
        pre {
          margin-left: 50px !important;
        }
      }
    }

    textarea {
      background-color: darken($dark, 15) !important;
      border: unset !important;
    }

    textarea,
    pre {
      caret-color: $white !important;
      font-size: 1.3em !important;
      color: $white !important;
      filter: saturate(1.5) !important;
      font-family: "Roboto Mono", monospace !important;
      font-weight: 500 !important;

      &:focus {
        outline: unset !important;
      }
    }
  }

  .section-title {
    margin-bottom: 1rem;
  }

  .console-container {
    position: sticky;
    top: 1rem;
  }

  .error {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-top: 0.4rem;
    color: $color2;
    // width: 100%;

    .error-message {
      margin-top: 0.5rem;
      display: block;
    }
  }
  .is-executing-marker {
    margin-right: 0.5rem;
    color: $neutralDarker;
    float: right;

    .anticon {
      margin-right: 0.5rem;
    }
  }

  .error-message,
  .console {
    color: darken($neutralDark, 30);
    background-color: $white;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    width: 100%;
    min-height: 2.7rem;
    box-sizing: border-box;
    filter: saturate(1.5) !important;
    font-family: "Roboto Mono", monospace !important;
    font-weight: 500 !important;
    border: 1px solid $neutralMid;
    max-height: 300px;
    overflow: auto;

    .log-row {
      display: block;
    }
  }

  .error-message {
    color: $color2;
    border-color: $color2;
    width: 100%;
  }
}
