@import "../Variables";

.important {
  // border: 1px solid $color1;
  background-color: darken($dark, 10);
  border-radius: 10px;
  color: lighten($color1, 40);
  color: $white;
  width: 100%;
  display: inline-block;
  padding: 1rem 1rem;
  // font-weight: bold;
  display: flex;
  align-items: center;

  .anticon {
    font-size: 1.2rem;
    margin-right: 0.8rem;
    color: $color1;
    // color: $white;
  }
}
