@import "../../Variables";

.lesson-functions {
  .call-stack-image-container {
    margin: auto;
    text-align: center;
    margin-top: 1rem;

    img {
      width: 100%;
      max-width: 500px;
    }
  }
}
