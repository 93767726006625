@import "../Variables";

body {
  font-size: 16px;
}

@media (max-width: 768px) {
  body {
    font-size: 18px;
  }
}

.app {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-width: 100vw;
  overflow: hidden;
  box-sizing: border-box;
  justify-content: space-between;

  .footer {
    min-height: 50px;
  }

  .page-content {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 500px;
    // height: 100%;
    flex: 1;

    .sidebar {
      width: 160px;
    }

    .inner-content {
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  // &.with-background {
  // background-color: lighten($neutralLight, 2);
  // }

  &.with-boxed-layout {
    .inner-content {
      padding: 1.5rem;

      & > div:first-child {
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .page-navigation-buttons {
    display: flex;
    justify-content: space-between;
    max-width: 1000px;
    margin: 0 auto;

    .prev {
      align-self: flex-start;
    }

    .next {
      align-self: flex-end;
    }
  }
}
