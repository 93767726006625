@import "../../Variables";

.lessons-page {
  .lesson-divider {
    width: 100%;
    height: 3px;
    background-color: $neutral;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .lesson-card {
    margin-bottom: 1rem;
  }
}
