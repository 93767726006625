@import "../Variables";

.exercise {
  width: 90vw !important;

  .requirement {
    border: 1px solid $neutral;
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 10px;
    margin-top: 0.5rem;
    font-weight: bold;
    color: $dark;
  }

  .code-editor-container {
    margin: 0;
    // padding: 0;
  }

  .ant-modal-body {
    padding-bottom: 0.5rem;
  }

  .footer {
    margin-bottom: 1rem;
    margin-top: 1rem;

    button {
      margin-left: 0.3rem;
    }

    .show-solution {
      margin-left: 2rem;
      margin-top: 1rem;
    }

    .solution-editor {
      margin-top: 2rem;
      width: 100%;
      // filter: grayscale(0.7);

      .code-editor-container {
        border: 1px solid $neutralMid;

        &:not(:last-child) {
          margin-bottom: 1rem !important;
        }
      }

      .code-editor,
      textarea {
        background-color: darken($dark, 5) !important;
      }
    }

    .problem {
      border: 1px solid lighten($color2, 20);
      border-radius: 10px;
      padding: 0.6rem 1rem;
      color: darken($color2, 20);
      margin-top: 1rem;
      display: block;
      margin-left: -0.3rem;

      .anticon {
        margin-right: 0.5rem;
      }
    }

    .is-solved {
      $solvedColor: $color3;
      background-color: $solvedColor;
      // width: calc(100% - 100px);
      display: inline-block;
      width: 100%;
      // width: calc(50% - 6px);
      border-radius: 10px;
      padding: 0.6rem 1rem;
      color: $white;
      // margin-left: 1rem;
      margin-top: 1rem;
      margin-left: -0.3rem;
      position: relative;
      // animation-name: success-entry;
      // animation-duration: 400ms;
      // animation-timing-function: ease-in;

      @keyframes icon-entry {
        from {
          transform: scale(20);
          opacity: 0;
        }

        to {
          transform: scale(2);
          opacity: 1;
        }
      }

      .anticon {
        margin-right: 0.5rem;
        color: $white;
        animation-name: icon-entry;
        animation-duration: 700ms;
        animation-timing-function: ease-in;
      }
    }
  }
}

.exercise-trigger {
  margin-right: 2rem;
}
