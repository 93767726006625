@import "../Variables";

.main-bar {
  color: $white;
  border-bottom: 1px solid $neutral;
  // position: sticky !important;
  top: 0 !important;
  z-index: 1;
  border-radius: 0 !important;

  .ant-card-body {
    padding: 0.7rem 1rem;
  }

  .daemon-status {
    position: absolute;
    right: -0.3rem;
    bottom: -0.6rem;
    transform: scale(0.8);
  }

  .logo-container {
    display: flex;
    align-items: center;

    .organisation-logo {
      color: $dark;
      font-size: 25px;
      // width: 180px;
      height: 39px;
    }

    .app-logo {
      height: 39px;
      margin-right: 1rem;
      padding-right: 1rem;
    }
  }

  .page-title {
    text-align: center;
    margin: 0;

    position: relative;
    top: 6px;

    .version {
      font-size: 0.8rem;
      display: inline-block;
      vertical-align: middle;
      margin-left: 0.2rem;
      font-weight: 400;
      position: relative;
      top: -1px;

      &.latest-version {
        color: #12cbc4;
      }

      &.old-version {
        color: gray;
      }
    }
  }

  .user-column {
    text-align: right;

    .user-dropdown-container {
      cursor: pointer;
      // padding-right: 0.5rem;
      // margin-right: -0.5rem;
      // border-radius: 15px;
      // transition: all 300ms;
      // background-color: transparent;

      // &:hover {
      //   background-color: rgba($neutralLight, 0.8);
      // }

      .dropdown-icon,
      .email {
        color: $dark;
      }
    }
  }

  @media print {
    display: none;
  }
}

.user-menu-highlight {
  color: $color1 !important;
}
