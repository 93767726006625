@import "../Variables";

.loading-screen {
  min-height: 400px;
  height: 100%;
  max-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
